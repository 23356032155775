.tabs {
    width: 100%;
}

.tabButtons {
    display: flex;
    border-bottom: 3px solid #2b2a33;
    gap: 16px;
}

.tabButton {
    padding: 10px 20px;
    cursor: pointer;
    border: 3px solid #2b2a33;
    border-bottom: 0;
    background: none;
    outline: none;
    border-radius: 8px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.tabButton.active {
    color: white;
    background: #2b2a33;
    font-weight: bold;
}

.tabContent {
    padding: 20px;
    border-top: 2px solid #ddd;
}
