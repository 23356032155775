.table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.table td,
.table th {
    border: 1px solid #444;
    padding: 12px;
    color: white;
}

.table th {
    background-color: #222;
}

.table td {
    background-color: #333;
    color: white;
}

.pagination {
    display: flex;
    max-width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #333;
    padding: 12px;
    box-sizing: border-box;
    text-align: center;
}

.pagination span {
    color: #fff;
}

.pagination button {
    padding: 6px 12px;
    color: #fff;
    background-color: #222;
    border: 0;
    border-radius: 8px;
    cursor: pointer;
}

.pagination button[disabled] {
    background-color: #444;
    cursor: not-allowed;
}
