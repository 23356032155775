.container {
    margin: 0 auto;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrapper {
    width: 360px;
    margin: 0 auto;
    background-color: #e3e3e3;
    border-radius: 8px;
    padding: 20px;
}
