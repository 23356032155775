.listActions {
    display: grid;
    gap: 12px;
    height: auto;
    grid-template-columns: repeat(2, 1fr);
}

.listActions button {
    border: 1px solid #4c4cff;
    background: transparent;
    border-radius: 4px;
    padding: 8px;
    cursor: pointer;
    transition:
        color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
}

.orange {
    border: 1px solid #f9b115 !important;
    color: #f9b115;
}

.orange:hover {
    background-color: #f9b115;
    color: white;
}

.red {
    border: 1px solid #e53935 !important;
    color: #e53935;
}

.red:hover {
    background-color: #e53935;
    color: white;
}

.green {
    border: 1px solid #43a047 !important;
    color: #43a047;
}

.green:hover {
    background: #43a047;
    color: white;
}
