.inputWrapper {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-top: 12px;
}

.inputWrapper.fullWidth {
    grid-column: span 2;
}

.passwordWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
}

input {
    background-color: #2b2a33;
    width: 100%;
    height: 36px;
    padding: 16px;
    box-sizing: border-box;
    border: none;
    border-radius: 8px;
    color: white;
}

textarea {
    background-color: #2b2a33;
    width: 100%;
    line-height: 36px;
    padding: 16px;
    box-sizing: border-box;
    border: none;
    border-radius: 8px;
    color: white;
}

input[type='file'] {
    height: auto;
}

select {
    background-color: #2b2a33;
    width: 100%;
    height: 36px;
    padding: 8px 16px;
    box-sizing: border-box;
    border: none;
    border-radius: 8px;
    color: white;
}

.passwordWrapper input {
    flex: 1;
    width: 100%;
    padding-right: 36px;
}

.toggleButton {
    flex-shrink: 0;
    position: absolute;
    right: 12px;
    background-color: transparent;
    border: none;
    height: 100%;
    display: flex;
    align-items: center;
}

.toggleButton svg {
    color: #fff;
    width: 20px;
}

.checkboxWrapper label {
    display: flex;
    align-items: center;
    gap: 16px;
}

.inputWrapper input[type='checkbox'] {
    width: 32px;
    height: 32px;
}
