.container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
}

.container.fullWidth {
    display: flex;
    flex-direction: column;
}

.actions {
    grid-column: span 2;
    display: flex;
    justify-content: flex-end;
    gap: 26px;
}

.actions button {
    height: 36px;
    line-height: 36px;
    border: none;
    padding: 0 26px;
    background-color: #3a2abb;
    cursor: pointer;
    border-radius: 8px;
    color: white;
}

@media (max-width: 768px) {
    .container {
        grid-template-columns: 1fr;
    }
}
