body,
html {
    background-color: #242424;
}

* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    color: #4c4cff;
}

.table-actions {
    /* display: flex; */
    /* justify-content: space-between; */
}
.table-actions > *:not(:first-child) {
    margin-left: 12px;
}

.table-actions button {
    padding: 4px 20px;
    font-weight: 600;
    cursor: pointer;
}

.rsw-editor {
    height: 360px;
    border-radius: 8px;
    background: #2b2a33;
}

.rsw-toolbar,
.rsw-btn:hover {
    background-color: #313131 !important;
}

.rsw-btn[data-active='true'] {
    background: #2b2a33 !important;
}

.rsw-btn,
.rsw-btn *,
.rsw-ce,
.rsw-ce * {
    color: white !important;
}

td,
th {
    border: 1px solid #444;
    padding: 12px;
    color: white;
}

th {
    background-color: #222;
}

td {
    background-color: #333;
    color: white;
}
