.container {
    display: flex;
    width: 100vw;
    height: 100vh;
    flex-direction: row;
    gap: 36px;
    padding: 40px;
    box-sizing: border-box;
}

.pageWrapper {
    flex: 6;
    background-color: #e3e3e3;
    border-radius: 8px;
    padding: 20px;
    overflow: scroll;
}
