.tableActionWrapper {
    display: flex;
    gap: 24px;
}

.tableActionWrapper input {
    flex: 4;
}

.tableActionWrapper button {
    flex: 1;
}

.tableDeleteColumn button {
    width: 100%;
}

.photoList {
    margin-top: 32px;
    display: flex;
    gap: 16px;
    list-style: none;
    flex-direction: column;
    align-items: flex-start;
}

.photoList li {
    display: inline-flex;
    position: relative;
    padding: 24px;
    border: 1px solid #2b2a33;
}

.removePhoto {
    position: absolute;
    top: 0;
    right: -46px;
    width: 36px;
    height: 36px;
    text-align: center;
    line-height: 34px;
    background-color: #2b2a33;
    border-radius: 3px;
    cursor: pointer;
    color: red;
    font-weight: bold;
    font-size: 32px;
}

.dragHandle {
    font-size: 32px;
    margin: auto 25px auto 0;
}
