.container {
    max-width: 360px;
    flex: 3;
    display: flex;
    flex-direction: column;
    width: 480px;
    background-color: #e3e3e3;
    border-radius: 8px;
    padding: 20px;
    gap: 20px;
}

.menuItem {
    margin-bottom: 10px;
}

.menuText {
    color: white;
    cursor: pointer;
    padding: 8px;
    border: 1px solid #373773;
    border-radius: 8px;
    background-color: #313131;
    display: flex;
    justify-content: space-between;
    transition: 0.3;
}

.active .menuText {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.active .menuText svg {
    transform: rotate(90deg);
}

.subMenu {
    display: none;
}

.subMenuItem {
    background-color: #313131;
    cursor: pointer;
    padding: 8px;
}

.subMenuItem a {
    color: white;
}

.subMenuItem:last-child {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}

.active .subMenu {
    display: block;
}
