.dragHandle {
    cursor: grab;
    font-size: 32px;
    user-select: none;
}

.sortableContainer tbody tr {
    position: relative;
    padding: 24px;
    border: 1px solid #2b2a33;
    user-select: none;
}
